import React from "react";
import SEO from "../components/seo";

const PrivacyPolicyV1 = props => {
    return (
        <>
            <SEO title="Privacy Policy V1" description={"We will never sell your personal data to anyone."}/>
            <section className={"privacy-policy"}>
                <div>
                    <h1>Privacy Policy</h1>
                    <p>
                        Your privacy is critically important to us. As owner and operators of Scrum Mate we, Adaptive Consulting Ltd. have a
                        few fundamental principles:
                    </p>
                    <ul>
                        <li>We don’t ask you for personal information unless we truly need it.</li>
                        <li>
                            We don’t share your personal information with anyone except to comply with the law, develop our products, or
                            protect our rights.
                        </li>
                        <li>
                            We don’t store personal information on our servers unless required for the on-going operation of one of our
                            services.
                        </li>
                    </ul>
                    <p>If you have questions about deleting or correcting your personal data please contact our support team.</p>
                </div>
                <div>
                    <h2>Website Visitors</h2>
                    <p>
                        Like most website operators, Adaptive Consulting collects non-personally-identifying information of the sort that
                        web browsers and servers typically make available, such as the browser type, language preference, referring site,
                        and the date and time of each visitor request. Adaptive Consulting’s purpose in collecting non-personally
                        identifying information is to better understand how Adaptive Consulting’s visitors use its website. From time to
                        time, Adaptive Consulting may release non-personally-identifying information in the aggregate, e.g., by publishing a
                        report on trends in the usage of its website.
                    </p>
                    <p>
                        Adaptive Consulting also collects potentially personally-identifying information like Internet Protocol (IP)
                        addresses for logged in users.
                    </p>
                </div>
                <div>
                    <h2>Gathering of Personally-Identifying Information</h2>
                    <p>
                        Certain visitors to Adaptive Consulting’s websites choose to interact with Adaptive Consulting in ways that require
                        Adaptive Consulting to gather personally-identifying information. The amount and type of information that Adaptive
                        Consulting gathers depends on the nature of the interaction. For example, we ask visitors who sign up for Scrum Mate
                        to provide a first name and email address. Those who engage in transactions with Adaptive Consulting – by purchasing
                        access to the Scrum Mate Service, for example – are asked to provide additional information, including as necessary
                        the personal and financial information required to process those transactions. In each case, Adaptive Consulting
                        collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor’s
                        interaction with Adaptive Consulting. Adaptive Consulting does not disclose personally-identifying information other
                        than as described below. And visitors can always refuse to supply personally-identifying information, with the
                        caveat that it may prevent them from engaging in certain activities.
                    </p>
                </div>
                <div>
                    <h2>Aggregated Statistics</h2>
                    <p>
                        Adaptive Consulting may collect statistics about the behavior of visitors to its websites. For instance, Adaptive
                        Consulting may monitor the most popular features of Scrum Mate. Adaptive Consulting may display this information
                        publicly or provide it to others. However, Adaptive Consulting does not disclose personally-identifying information
                        other than as described below.
                    </p>
                </div>
                <div>
                    <h2>Collecting behavioral patterns</h2>
                    <p>
                        Scrum Mate is using FullStory's (
                        <a href="https://www.fullstory.com" target="_blank" rel="noopener noreferrer">
                            https://www.fullstory.com
                        </a>
                        ) service to collect behavioral patterns across the application pages during the first session after a new user
                        activates a Scrum Mate account.{" "}
                        <strong>
                            The sole purpose of collecting behavioral data is to improve our onboarding experience and shorten the learning
                            time for new users.{" "}
                        </strong>
                    </p>
                    <ul>
                        <li>We collect behavioral data during the first visit only after activation, and never again.</li>
                        <li>
                            Once you finished your first session (closed the browser window) after activating your new Scrum Mate account,
                            behavioral data won’t be collected anymore.
                        </li>
                        <li>All data will be deleted after 15 days.</li>
                        <li>
                            You may opt-out from having FullStory collect your information when visiting Scrum Mate at any time by using a
                            ‘Do Not Track’ header. You can also opt-out by{" "}
                            <a href="https://www.fullstory.com/optout/" target="_blank" rel="noopener noreferrer">
                                clicking here
                            </a>
                            .
                        </li>
                    </ul>
                    <p>
                        Both FullStory and Scrum Mate teams take a number of steps to ensure your privacy is respected. You can learn about
                        FullStory's privacy policy{" "}
                        <a href="https://www.fullstory.com/legal/privacy/" target="_blank" rel="noopener noreferrer">
                            here
                        </a>
                        .
                    </p>
                </div>
                <div>
                    <h2>Protection of Certain Personally-Identifying Information</h2>
                    <p>
                        Adaptive Consulting discloses potentially personally-identifying and personally-identifying information only to
                        those of its employees, contractors and affiliated organizations that (i) need to know that information in order to
                        process it on Adaptive Consulting’s behalf or to provide services available at Adaptive Consulting’s websites, and
                        (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated
                        organizations may be located outside of your home country; by using Adaptive Consulting’s websites, you consent to
                        the transfer of such information to them. Adaptive Consulting will not rent or sell potentially
                        personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors
                        and affiliated organizations, as described above, Adaptive Consulting discloses potentially personally-identifying
                        and personally-identifying information only in response to a subpoena, court order or other governmental request, or
                        when Adaptive Consulting believes in good faith that disclosure is reasonably necessary to protect the property or
                        rights of Adaptive Consulting, third parties or the public at large. If you are a registered user of Scrum Mate and
                        have supplied your email address, Adaptive Consulting may occasionally send you an email to tell you about new
                        features, solicit your feedback, or just keep you up to date with what’s going on with Adaptive Consulting and our
                        products. We primarily use our various product blogs to communicate this type of information, so we expect to keep
                        this type of email to a minimum. If you send us a request (for example via a support email or via one of our
                        feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or
                        to help us support other users. Adaptive Consulting takes all measures reasonably necessary to protect against the
                        unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying
                        information.
                    </p>
                </div>
                <div>
                    <h2>Cookies</h2>
                    <p>
                        A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser
                        provides to the website each time the visitor returns. Adaptive Consulting uses cookies to help Scrum Mate identify
                        and track visitors, their usage of Scrum Mate service, and their website access preferences. Adaptive Consulting
                        visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies
                        before using Adaptive Consulting’s websites, with the drawback that certain features of Adaptive Consulting’s
                        websites may not function properly without the aid of cookies.
                    </p>
                </div>
                <div>
                    <h2>Business Transfers</h2>
                    <p>
                        If Adaptive Consulting, or substantially all of its assets, were acquired, or in the unlikely event that Adaptive
                        Consulting goes out of business or enters bankruptcy, user information would be one of the assets that is
                        transferred or acquired by a third party. You acknowledge that such transfers may occur and that any acquirer of
                        Adaptive Consulting may continue to use your personal information as set forth in this policy.
                    </p>
                </div>
                <div>
                    <h2>Privacy Policy Changes</h2>
                    <p>
                        Although most changes are likely to be minor, Adaptive Consulting may change its Privacy Policy from time to time,
                        and in Adaptive Consulting’s sole discretion. Adaptive Consulting encourages visitors to frequently check this page
                        for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will
                        constitute your acceptance of such change.
                    </p>
                    <p>
                        This Privacy Policy is an adapted version of{" "}
                        <i>
                            <a href="http://automattic.com/privacy/" target={"_blank"} rel="noopener noreferrer">
                                automattic.com/privacy
                            </a>
                        </i>{" "}
                        which is generously availabe under a Creative Commons Sharealike license.
                    </p>
                </div>
            </section>
        </>
    );
};

export default PrivacyPolicyV1;
